import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

import * as turf from "@turf/turf";

import axios from 'axios';

import i18n from 'i18next';

import { LANGUAGE } from '../consts/app';
import { GEOCODING_API } from '../consts/map';

export const getAddress = async (lng, lat) => {
  let r = await axios.get(
    `${GEOCODING_API}/mapbox.places/${lng},${lat}.json`,
    {
      'params': {
        'types': 'address',
        'access_token': mapboxgl.accessToken,
        'language': LANGUAGE,
      },
    },
  );
  // let name = `${feature['text']} ${feature["address"] === undefined ? "" : feature["address"]}`
  return r.data?.features[0]?.place_name || '-';
};

const R = 6371;  // radius of earth in km

function toRad(degrees) {
  return degrees * Math.PI / 180;
}

function pointToRad(point) {
  const [lon, lat] = point;
  return [toRad(lon), toRad(lat)];
}

export const getDistance = (pointA, pointB) => {
  // /* Haversine formula */
  const [lonA, latA] = pointToRad(pointA);
  const [lonB, latB] = pointToRad(pointB);

  const dLon = lonB - lonA;
  const dLat = latB - latA;

  const a = Math.sin(dLat / 2) ** 2 + Math.cos(latA) * Math.cos(latB) * Math.sin(dLon / 2) ** 2;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = R * c * 1000;  // meters
  return distance;
}

export const formatDistance = meters => {
  if (meters < 1000) {
    return `${meters.toFixed(0)} ${i18n.t('m away')}`;
  } else {
    let kilometers = (meters / 1000).toFixed(2);
    return `${(kilometers.replace(/\.?0+$/, ''))} ${i18n.t('km away')}`;
  };
}

export const getMapEventLayers = (e, map) => map.queryRenderedFeatures(e.point).map(feature => feature.layer.id);

export const getCompassAngle = () => {
  const regex = /rotateZ\((-?\d+(\.\d+)?)deg\)/;

  const transformation = document.getElementsByClassName('mapboxgl-user-location-show-heading')[0].style.transform;
  const match = transformation.match(regex);
  return match ? parseFloat(match[1]) : 0;
};

export const getZoomBasedOnCircle = (map) => {
  const bbox = turf.bbox(map.getSource('circleData')._data);
  map.fitBounds(bbox, {
    padding: 50,
    duration: 0,
    bearing: map.getBearing(),
    pitch: map.getPitch(),
  }, { geolocateSource: true });
  const currentZoom = map.getZoom();
  return currentZoom;
};

export const calculateBearing = (alpha, beta, gamma) => {
  /* based on https://stackoverflow.com/questions/60624644/deviceorientation-compass-android */
  let compass = -(alpha + beta * gamma / 90);
  compass -= Math.floor(compass / 360) * 360;
  return compass;
};

export const coordinatesEqual = (c1, c2, tolerance = 1e-5) => (
  c1.length === 2 && c2.length === 2 &&
  Math.abs(c1[0] - c2[0]) <= tolerance &&
  Math.abs(c1[1] - c2[1]) <= tolerance
);

export const calcZoomDistance = (zoom) => 1832529 * Math.pow(Math.E, -0.7 * zoom);